import { SupplierUrls, SuppliernotesUrls, manageProfile } from 'api/apiUrls';
import apiService from 'api/apiService';

const apiUrl = { ...SupplierUrls, ...SuppliernotesUrls, ...manageProfile };
const jwt = localStorage.getItem('jwt');
console.log('supplierjwt', jwt)

const supplierService = {

    getSupplierlist: (pn, ps) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.getSupplierUrl + "?pn=" + pn + "&ps=" + ps, 'GET', '', false, jwt,
                result => {
                    var supplierList = result.data.data.content;
                    console.log('Supplier table', supplierList);
                    resolve(supplierList);
                },
                error => {
                    reject(error);
                }
            );
        });
    },

    getAllSupplierlist: () => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.getAllSupplierUrl, 'GET', '', false, jwt,
                result => {
                    console.log(result);
                    var supplierList = result.data.data;
                    console.log('Supplier table', supplierList);
                    resolve(supplierList);
                },
                error => {
                    reject(error);
                }
            );
        });
    },

    getAllqualification: () => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.getQualificationUrl, 'GET', '', false, jwt,
                result => {
                    var qualification = result.data.data;
                    console.log('qualificatoin list', qualification);
                    resolve(qualification);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getSuplierbyId: (id) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.getSupplierbyId + "?supplierId=" + id, 'GET', '', false, jwt,
                result => {
                    var supplierbyId = result.data.data;
                    console.log('Supplier table byid', supplierbyId);
                    resolve(supplierbyId);
                },
                error => {
                    reject(error);
                }
            );
        });
    },



    // postSupplierService: (form) => {

    //     return new Promise((resolve, reject) => {
    //         apiService(apiUrl.postSupplierUrl, 'POST', form, false, jwt,
    //             result => {
    //                 var res = result.data
    //                 resolve(res)
    //             },
    //             error => {
    //                 reject(error)
    //             }
    //         )
    //     })
    // },

    postSupplierService: (form) => {

        return new Promise((resolve, reject) => {
            apiService(apiUrl.postSupplierUrl, 'POST', form, true, jwt,
                result => {
                    var res = result.data
                    resolve(res)
                },
                error => {
                    reject(error)
                }
            )
        })
    },



    deleteSupplier: (id) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.deleteSupplierUrl + id, 'DELETE', '', false, jwt,
                result => {
                    var res = result.data
                    resolve(res)
                },
                error => {
                    reject(error)
                }
            )
        })
    },






    // notes

    getSuppliernotes: (id) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.getSuppliernotesUrl + "?supplierId=" + id, 'GET', '', false, jwt,
                result => {
                    var supplierList = result.data.data;
                    console.log('Supplier table', supplierList);
                    resolve(supplierList);
                },
                error => {
                    reject(error);
                }
            );
        });
    },



    postSuppliernotes: (form) => {

        return new Promise((resolve, reject) => {
            apiService(apiUrl.postSuppliernotesUrl, 'POST', form, false, jwt,
                result => {
                    var res = result.data
                    resolve(res)
                },
                error => {
                    reject(error)
                }
            )
        })
    },

    deleteSuppliernotes: (id) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.deleteSuppliernotesUrl + id, 'DELETE', '', false, jwt,
                result => {
                    var res = result.data
                    resolve(res)
                },
                error => {
                    reject(error)
                }
            )
        })
    },



    deleteSupplierDocument: (id) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.deleteDocumentUrl + id, 'DELETE', '', false, jwt,
                result => {
                    var res = result.data
                    resolve(res)
                },
                error => {
                    reject(error)
                }
            )
        })
    },

    postSupplierDocuments: (form) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.postSupplierDocumentsUrl, 'POST', form, true, jwt,
                result => {
                    var res = result.data
                    resolve(res)
                },
                error => {
                    reject(error)
                }
            )
        })
    },


    getW9coireport: (docType, supplierId) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.w9CoireportUrl + "?supplierId=" + supplierId + "&type=" + docType, 'GET', '', false, jwt,
                result => {
                    var docValue = result.data.data;
                    console.log('Supplier document', docValue);
                    resolve(docValue);
                },
                error => {
                    reject(error);
                }
            );
        });
    },

    getSupplierinvoicereport: (supId, status, date) => {
        return new Promise((resolve, reject) => {
            apiService(apiUrl.supplierInvoiceReportUrl + "?supplierId=" + supId + "&status=" + status + "&date=" + date, 'GET', '', false, jwt,
                result => {
                    var supInvReport = result.data.data;
                    console.log('Supplier document', supInvReport);
                    resolve(supInvReport);
                },
                error => {
                    reject(error);
                }
            );
        });
    },



}

export { supplierService }