import { Alert } from 'antd'
import moment from 'moment'
import React,{useState,useEffect} from 'react'
import { supplierRepo } from 'repository/SupplierRepository'
const AlertW9Doc = () => {
    const userData = JSON.parse(localStorage.getItem('UserData'))

    const [showAlert, setShowAlert] = useState(false)
    useEffect(() => {
        getSupplierbyId(userData?.id)
    }, [])


    const getSupplierbyId = async (id) => {
        try {
            const supplierById = await supplierRepo.getsupplierbyId(id);
            console.log('supplierById', supplierById)
            var w9ExpiryDate = moment(supplierById[0]?.w9documentsData[0]?.w9year).endOf('year').format('YYYY-MM-DD');
            console.log(w9ExpiryDate);
            var currentDate = moment().subtract(14,'days').format('YYYY-MM-DD');
            console.log(currentDate);
            if(moment(w9ExpiryDate).isAfter(currentDate)){
                setShowAlert(true)
            }
        } catch (e) {
            console.error(e)
        }
    }
  return (
    showAlert?(
    <div  style={{ margin: '35px',display:'flex' ,gap:10,marginTop:16,maxHeight:35}}>
        <Alert message="Please update latest W9 Document in profile section" type="error" showIcon closable />
        
    </div>):null
  )
}

export default AlertW9Doc