// import { useState, useEffect } from 'react'
// import { useRoutes } from 'react-router-dom';

// // routes
// import MainRoutes from './MainRoutes';
// import UserRoutes from './UserRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
// import SupplierRoutes from './SupplierRoutes'
// import NotFoundPage from 'views/pages/Not-Found';
// // ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {

//     const [userRoleName, setUserRoleName] = useState('')

//     useEffect(() => {
//         const userRole = localStorage.getItem('userRole');
//         console.log(userRole);
//         if (userRole) {
//             const parsedUserRole = JSON.parse(userRole);
//             setUserRoleName(parsedUserRole.name);
//         }
//     }, [userRoleName]);

//     return useRoutes(
//         [
//             AuthenticationRoutes,
//             // userRoleName === 'ADMIN' ? MainRoutes :  UserRoutes
//             userRoleName == 'ADMIN' ? MainRoutes : userRoleName == 'Supplier' ? SupplierRoutes : UserRoutes,
//             { path: '*', element: <NotFoundPage /> }

//         ]);

// }
import { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

import MainRoutes from './MainRoutes';
import UserRoutes from './UserRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import SupplierRoutes from './SupplierRoutes';
import NotFoundPage from 'views/pages/Not-Found';

export default function ThemeRoutes() {
    const [userRoleName, setUserRoleName] = useState('');

    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        if (userRole) {
            const parsedUserRole = JSON.parse(userRole);
            setUserRoleName(parsedUserRole.name);
        }
    }, []);

    const routes = useRoutes([
        AuthenticationRoutes,MainRoutes,SupplierRoutes,
        // userRoleName === 'ADMIN' ? MainRoutes :
        // userRoleName === 'Supplier' ? SupplierRoutes :
        UserRoutes,
        { path: '*', element: <NotFoundPage /> }
    ]);

    return routes;
}
