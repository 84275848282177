// // import { useEffect } from 'react';
// // import { useDispatch, useSelector } from 'react-redux';
// // import { Outlet } from 'react-router-dom';

// // // material-ui
// // import { styled, useTheme } from '@mui/material/styles';
// // import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// // // project imports
// // import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// // import Header from './Header';
// // import Sidebar from './Sidebar';
// // import Customization from '../Customization';
// // import navigation from 'menu-items';
// // import { drawerWidth } from 'store/constant';
// // import { SET_MENU } from 'store/actions';
// // import theme1 from '../../assets/scss/_themes-vars.module.scss'
// // // assets
// // import { IconChevronRight } from '@tabler/icons';
// // import Sidebarnew from './Sidebar/SidebarNew';
// // // styles
// // const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
// //     ...theme.typography.mainContent,
// //     ...(!open && {
// //         borderBottomLeftRadius: 0,
// //         borderBottomRightRadius: 0,
// //         transition: theme.transitions.create('margin', {
// //             easing: theme.transitions.easing.sharp,
// //             duration: theme.transitions.duration.leavingScreen
// //         }),
// //         [theme.breakpoints.up('md')]: {
// //             marginLeft: -(drawerWidth - 20),
// //             width: `calc(100% - ${drawerWidth}px)`
// //         },
// //         [theme.breakpoints.down('md')]: {
// //             marginLeft: '20px',
// //             width: `calc(100% - ${drawerWidth}px)`,
// //             padding: '16px'
// //         },
// //         [theme.breakpoints.down('sm')]: {
// //             marginLeft: '10px',
// //             width: `calc(100% - ${drawerWidth}px)`,
// //             padding: '16px',
// //             marginRight: '10px'
// //         }
// //     }),
// //     ...(open && {
// //         transition: theme.transitions.create('margin', {
// //             easing: theme.transitions.easing.easeOut,
// //             duration: theme.transitions.duration.enteringScreen
// //         }),
// //         marginLeft: 0,
// //         borderBottomLeftRadius: 0,
// //         borderBottomRightRadius: 0,
// //         width: `calc(100% - ${drawerWidth}px)`,
// //         [theme.breakpoints.down('md')]: {
// //             marginLeft: '20px'
// //         },
// //         [theme.breakpoints.down('sm')]: {
// //             marginLeft: '10px'
// //         }
// //     })
// // }));

// // // ==============================|| MAIN LAYOUT ||============================== //

// // const MainLayout = () => {
// //     const theme = useTheme();
// //     const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

// //     // Handle left drawer
// //     const leftDrawerOpened = useSelector((state) => state.customization.opened);
// //     const dispatch = useDispatch();
// //     const handleLeftDrawerToggle = () => {
// //         dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
// //     };

// //     useEffect(() => {
// //         dispatch({ type: SET_MENU, opened: !matchDownMd });
// //         // eslint-disable-next-line react-hooks/exhaustive-deps
// //     }, [matchDownMd]);

// //     return (
// //         <Box sx={{ display: 'flex' }}>
// //             <CssBaseline />
// //             {/* header */}
// //             <AppBar
// //                 enableColorOnDark
// //                 position="fixed"
// //                 color="inherit"
// //                 elevation={0}
// //                 sx={{
// //                     bgcolor: theme1.headerColor,
// //                     transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
// //                 }}
// //             >
// //                 <Toolbar>
// //                     <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
// //                 </Toolbar>
// //             </AppBar>
// //             <Sidebarnew/>
// //             {/* <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> */}

// //             <Main theme={theme} open={leftDrawerOpened}>
// //                 {/* breadcrumb */}
// //                 <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
// //                 <Outlet />
// //             </Main>
// //             <Customization />
// //         </Box>
// //     );
// // };

// // export default MainLayout;
// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Outlet } from 'react-router-dom';

// // material-ui
// import { styled, useTheme } from '@mui/material/styles';
// import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// // project imports
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Header from './Header';
// import Sidebarnew from './Sidebar/SidebarNew';
// import Customization from '../Customization';
// import navigation from 'menu-items';
// import { drawerWidth } from 'store/constant';
// import { SET_MENU } from 'store/actions';
// import theme1 from '../../assets/scss/_themes-vars.module.scss';
// import { IconChevronRight } from '@tabler/icons';

// // styles
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
//     ...theme.typography.mainContent,
//     ...(!open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginLeft: -(drawerWidth - 20),
//         width: `calc(100% - ${drawerWidth}px)`,
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px',
//             width: `calc(100% - ${drawerWidth}px)`,
//             padding: '16px',
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '10px',
//             width: `calc(100% - ${drawerWidth}px)`,
//             padding: '16px',
//             marginRight: '10px',
//         },
//     }),
//     ...(open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//         marginLeft: 0,
//         width: `calc(100% - ${drawerWidth}px)`,
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px',
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '10px',
//         },
//     }),
// }));

// const MainLayout = () => {
//     const theme = useTheme();
//     const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

//     // Handle left drawer
//     const leftDrawerOpened = useSelector((state) => state.customization.opened);
//     const dispatch = useDispatch();
//     const handleLeftDrawerToggle = () => {
//         dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
//     };

//     useEffect(() => {
//         dispatch({ type: SET_MENU, opened: !matchDownMd });
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [matchDownMd]);

//     return (
//         <Box sx={{ display: 'flex' }}>
//             {/* <CssBaseline /> */}
//             {/* <AppBar
//                 enableColorOnDark
//                 position="fixed"
//                 color="inherit"
//                 elevation={0}
//                 sx={{
//                     bgcolor: theme1.headerColor,
//                     zIndex: theme.zIndex.drawer + 1, // Ensure the AppBar is above the Drawer
//                     transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
//                 }}
//             > */}
//                 {/* <Toolbar>
//                     <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
//                 </Toolbar> */}
//             {/* </AppBar> */}
//             <Sidebarnew />
//             {/* <Main theme={theme} open={leftDrawerOpened}>
//                 <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
//                 <Outlet />
//             {/* </Main> */}
//             {/* <Customization /> */}
//         </Box>
//     );
// };

// export default MainLayout;
// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Outlet } from 'react-router-dom';
// import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
// // project imports
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Sidebarnew from './Sidebar/SidebarNew';
// import Customization from '../Customization';
// import navigation from 'menu-items';
// import { drawerWidth } from 'store/constant';
// import { SET_MENU } from 'store/actions';
// import theme1 from '../../assets/scss/_themes-vars.module.scss';
// import { IconChevronRight } from '@tabler/icons';

// // styles
// import { styled, useTheme } from '@mui/material/styles';

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
//     ...theme.typography.mainContent,
//     ...(!open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginLeft: -(drawerWidth - 20),
//         width: `calc(100% - ${drawerWidth}px)`,
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px',
//             width: `calc(100% - ${drawerWidth}px)`,
//             padding: '16px',
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '10px',
//             width: `calc(100% - ${drawerWidth}px)`,
//             padding: '16px',
//             marginRight: '10px',
//         },
//     }),
//     ...(open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//         marginLeft: 0,
//         width: `calc(100% - ${drawerWidth}px)`,
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px',
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '10px',
//         },
//     }),
//     paddingTop: 64, // Add padding to avoid overlap with Sidebarnew header
// }));

// const MainLayout = () => {
//     const theme = useTheme();
//     const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

//     // Handle left drawer
//     const leftDrawerOpened = useSelector((state) => state.customization.opened);
//     const dispatch = useDispatch();
//     const handleLeftDrawerToggle = () => {
//         dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
//     };

//     useEffect(() => {
//         dispatch({ type: SET_MENU, opened: !matchDownMd });
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [matchDownMd]);

//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             <Sidebarnew />
//             {/* <Main theme={theme} open={leftDrawerOpened}>
//                 <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
//                 <Outlet theme={theme} />
//             {/* </Main> */}
//             <Customization />
//         </Box>
//     );
// };

// export default MainLayout;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
// project imports
import Sidebarnew from './Sidebar-antd/SidebarNew';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import theme1 from '../../assets/scss/_themes-vars.module.scss';
import { IconChevronRight } from '@tabler/icons';

// styles
import { styled, useTheme } from '@mui/material/styles';

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <Sidebarnew />
        {/* <Box sx={{ flexGrow: 1, paddingTop: '150px' }}> 
            <Outlet />
        </Box> */}
        <Customization />
    </Box>
    );
};

export default MainLayout;
