import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { logoutUser } from 'path-to-your-logout-action'; // Import your logout actioA
import { Authlogin } from 'views/pages/authentication/authentication3/Login3'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import Routes from 'routes';
import { Navigate, useNavigate } from 'react-router';
const App = () => {
    const navigate = useNavigate()
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        let inactivityTimeout;

        const resetTimeout = () => {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(() => {
                navigate('/sessionexpired')
                setIsLoggedIn(false);
            // }, 10 * 1000); // 10sec
            }, 30 * 60 * 1000); //30mins
        };

        const activityHandler = () => {
            resetTimeout();
        };

        window.addEventListener('mousemove', activityHandler);
        window.addEventListener('keydown', activityHandler);

        resetTimeout();

        return () => {
            window.removeEventListener('mousemove', activityHandler);
            window.removeEventListener('keydown', activityHandler);
            clearTimeout(inactivityTimeout);
        };
    }, [dispatch]);


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
