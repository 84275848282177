import moment from "moment";
import { useState, useEffect } from "react";
import 'moment-timezone';

const useFormatDate = (date) => {
    
    return moment.utc(date).tz('America/Chicago').format('MMM DD, YYYY HH:mm')
}

export default useFormatDate;
