import React, { useEffect, useState } from 'react'
import { Alert } from 'antd'
import { supplierRepo } from 'repository/SupplierRepository'
import moment from 'moment'
const AlertCoiDoc = () => {
    const userData = JSON.parse(localStorage.getItem('UserData'))

    const [showAlert, setShowAlert] = useState(false)
    useEffect(() => {
        getSupplierbyId(userData?.id)
    }, [])


    const getSupplierbyId = async (id) => {
        try {
            const supplierById = await supplierRepo.getsupplierbyId(id);
            console.log('supplierById', supplierById)
            var coiExpiryDate = moment(supplierById[0]?.coidocumentsData[0]?.coiExpiry);
            var currentDate = moment().subtract(14,'days').format('YYYY-MM-DD');
            console.log(currentDate);
            if(moment(coiExpiryDate).isAfter(currentDate)){
                setShowAlert(true)
            }
        } catch (e) {
            console.error(e)
        }
    }


    return (
        showAlert?(
            <div style={{ margin: '35px', display: 'flex', gap: 0, marginTop: 16, maxHeight: 35 }}>
            <Alert message="Please update latest COI Document in profile section" type="error" showIcon closable />
        </div>
        ):null
       
    )
}

export default AlertCoiDoc





















// import React, { useEffect, useState } from 'react';
// import { Alert } from 'antd';
// import { supplierRepo } from 'repository/SupplierRepository';
// import moment from 'moment';

// const AlertW9Doc = () => {
//     const userData = JSON.parse(localStorage.getItem('UserData'));
//     const [showAlert, setShowAlert] = useState(false);

//     useEffect(() => {
//         if (userData?.id) {
//             getSupplierbyId(userData.id);
//         }
//     }, [userData?.id]);

//     const getSupplierbyId = async (id) => {
//         try {
//             const supplierById = await supplierRepo.getsupplierbyId(id);
//             if (supplierById.length > 0 && supplierById[0]?.coidocumentsData.length > 0) {
//                 const coiExpiryDate = moment(supplierById[0].coidocumentsData[0].coiExpiry);
//                 const currentDate = moment();
//                 const alertDate = moment(coiExpiryDate).subtract(14, 'days');

//                 if (currentDate.isAfter(alertDate) && currentDate.isBefore(coiExpiryDate)) {
//                     setShowAlert(true);
//                 }
//             }
//         } catch (e) {
//             console.error(e);
//         }
//     };

//     return (
//         showAlert ? (
//             <div style={{ margin: '35px', display: 'flex', gap: 0, marginTop: 16, maxHeight: 35 }}>
//                 <Alert message="Please update latest COI Document in profile section" type="error" showIcon closable />
//             </div>
//         ) : null
//     );
// };

// export default AlertW9Doc;
