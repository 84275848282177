const isProduction = process.env.NODE_ENV === 'production';

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    apiBaseUrl: isProduction ? 'https://intranet.gj-consultinggroup.com' : 'http://localhost:3000'
};

export default config;
