import Swal from 'sweetalert2';

export const showConfirmationAlertPassword = (className) => {
    return Swal.fire({
        title: "Password Reset",
        text: "Your Password has been Expired.Please reset your passord!",
        icon: "warning",
        showCancelButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        customClass: {
            container: className // Define your custom CSS class name
        }
    })


};
