import moment from 'moment/moment';
import { ApiUrls } from '../api/apiEnvironmentUrls';
import axios from 'axios';


/* Project Import will lives here */
export default function (url, method, data, isformdata, token, sucessCallback, errorCallback) {
    axios({
            method: method,
            url: `${ApiUrls.apiEnvironment}` + url,
            headers: {                
                'content-type': isformdata ? 'multipart/form-data' : 'application/json',
                // accept: 'application/json',
                'Authorization':'Bearer ' + token,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Access-Control-Allow-Headers': '*'
            },

            data: data || undefined
            // data: data ? data :  undefined
        }).then((payload) => {
            // console.log(payload);
            if (typeof sucessCallback === "function") {
                sucessCallback(payload);
            }
        })
        .catch(function (error) {
            ErrorHandling()
            // if (typeof errorCallback === "function") {
               
            //     errorCallback(error);
            // }
            if (typeof errorCallback === "function") {
                if (error?.response && error?.response?.status === 500) {
                    var msg = { message: "Unexpected Error. Please contact Administrator" }
                    return errorCallback(msg);
                }
            }
            console.log(error);
            
        });
}



const ErrorHandling = () => {
    // const navigate = useNavigate();
console.log('error handling')
        const loggedDate = localStorage.getItem('loggedDate');
        if (loggedDate) {
            console.log('error handling date')

            const currentDate = moment();
            const loggedDateMoment = moment(loggedDate).add(1, 'day');

            console.log("Current Date:", currentDate);
            console.log("Logged Date:", loggedDateMoment);

            if (currentDate.isAfter(loggedDateMoment)) {
                console.log('Login expired');
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log('Login still valid');
            }
        } else {
            console.log('No logged date found');
        }

};
