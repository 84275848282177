// assets
import { IconBrandChrome, IconArrowDownRightCircle, IconServer, IconBoxMargin, IconHelp, IconDashboard, IconHandClick, IconAperture, IconCode, IconSettings, IconDeviceDesktop, IconFileText, IconUser, IconReportSearch, IconHome, IconUserCircle } from '@tabler/icons';
// constant

const icons = { IconBrandChrome, IconArrowDownRightCircle, IconServer, IconBoxMargin, IconHelp, IconDashboard, IconHandClick, IconAperture, IconCode, IconSettings, IconFileText, IconDeviceDesktop, IconUser, IconReportSearch, IconHome, IconUserCircle };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const supplierMenu = {
    id: 'supplier-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'supplierinvoice',
            title: 'Invoice',
            type: 'item',
            url: '/supplierinvoice',
            icon: icons.IconServer,
            breadcrumbs: false
        },
        {
            id: 'SupplierUser',
            title: 'Consultants',
            type: 'item',
            url: '/SupplierUser',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            url: '',
            icon: icons.IconAperture,
            external: false,
            breadcrumbs: false,
            children: [
                {
                    id: 'reports',
                    title: 'Consultant Hours',
                    type: 'item',
                    url: '/contractoremp',
                    icon: icons.IconAperture,
                    external: false,
                }
            ]
        }
    ]
}
export default supplierMenu;
