import React from 'react';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import {
    DesktopOutlined,
    FileOutlined,
    FileExclamationOutlined,
    FileExclamationTwoTone,
    ClockCircleTwoTone,
    PieChartTwoTone,
    ClockCircleOutlined,
    ProjectOutlined,
    AppstoreTwoTone,
    FundProjectionScreenOutlined,
    UserSwitchOutlined,
    AppstoreOutlined,
    FileSearchOutlined,
    PieChartOutlined,
    FundTwoTone,
    IdcardTwoTone,
    TeamOutlined,
    UserOutlined,
    SnippetsTwoTone,
    BankTwoTone,
    SmileTwoTone,
    LogoutOutlined,
    SettingOutlined,
    StarOutlined,
    HighlightOutlined
} from '@ant-design/icons';
import { IconTrekking } from '@tabler/icons-react';
const ProfileSettingSupplier = Loadable(
    lazy(() => import('views/screens/profile/profilesettingSupplier'))
);
const ProfileSetting = Loadable(
    lazy(() => import('views/screens/profile/profilesetting'))
);
const ManageProfile = Loadable(
    lazy(() =>
        import('views/modules/AdminModules/TalentAcquision/ManageProfile')
    )
);
const ManagePosition = Loadable(
    lazy(() =>
        import('views/modules/AdminModules/TalentAcquision/ManagePosition')
    )
);
const Dashboard = Loadable(
    lazy(() => import('views/screens/dashboard/MainDashboard/index'))
);
const Client = Loadable(lazy(() => import('views/screens/admin/client/index')));
const Project = Loadable(
    lazy(() => import('views/screens/admin/project/index'))
);
const ContractorempReport = Loadable(
    lazy(() => import('views/screens/report/ContractorEmpReport/index'))
);
const FteempReport = Loadable(
    lazy(() => import('views/screens/report/FteEmpReport/index'))
);
const UserReport = Loadable(
    lazy(() => import('views/screens/report/UserReport/index'))
);
const User = Loadable(lazy(() => import('views/screens/admin/user/index')));
const Supplier = Loadable(
    lazy(() => import('views/screens/admin/supplier/index'))
);
const RoleUser = Loadable(
    lazy(() => import('views/screens/admin/role/index'))
);
const TimesheetStatusReport = Loadable(
    lazy(() => import('views/screens/report/TimesheetStatusReport/index'))
);
const Timesheet = Loadable(
    lazy(() => import('views/screens/time_sheet_dashboard/index'))
);
const TimesheetAdmin = Loadable(
    lazy(() => import('views/screens/admin/timsheet/index'))
);
const UserListCard = Loadable(
    lazy(() =>
        import(
            'views/screens/dashboard/adminDashboard/submittedlistDashboard/index'
        )
    )
);
const UserWeeksheet = Loadable(
    lazy(() =>
        import('views/screens/dashboard/adminDashboard/userTimesheet/index')
    )
);
const Settings = Loadable(
    lazy(() => import('views/screens/admin/settings/index'))
);
const AssetTrackingAdmin = Loadable(
    lazy(() => import('views/modules/AdminModules/assetTracking/index'))
);
const TasksAdmin = Loadable(
    lazy(() => import('views/modules/AdminModules/Tasks/index'))
);
const TasksUser = Loadable(
    lazy(() => import('views/modules/UserModules/Tasks/index'))
);
const AssetTrackingReport = Loadable(
    lazy(() => import('views/screens/report/AssetTrackingReport/index'))
);
const AssetTrackingCountReport = Loadable(
    lazy(() => import('views/screens/report/AssetTrackingCount/index'))
);
const InvoiceReportGraph = Loadable(
    lazy(() => import('views/screens/report/InvoiceReport/Graphindex'))
);
const UtilsTypography = Loadable(
    lazy(() => import('views/utilities/Typography'))
);
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(
    lazy(() => import('views/utilities/MaterialIcons'))
);
const UtilsTablerIcons = Loadable(
    lazy(() => import('views/utilities/TablerIcons'))
);
const Talentacquisoin = Loadable(
    lazy(() => import('views/modules/AdminModules/TalentAcquision/index'))
);
const TalentProfileView = Loadable(
    lazy(() =>
        import('views/modules/AdminModules/TalentAcquision/TalentprofileView')
    )
);
const SupplierInvoiceAdmin = Loadable(
    lazy(() => import('views/modules/AdminModules/Invoice/index'))
);
const SupplierUser = Loadable(
    lazy(() => import('views/modules/SupplierModules/suppplierUser/index'))
);
const W9CoiReport = Loadable(
    lazy(() => import('views/screens/report/w9CoiReport/index'))
);
const SupplierinvoiceReport = Loadable(
    lazy(() => import('views/screens/report/SupplierInvoiceReport/index'))
);
const ConsultantUserReport = Loadable(
    lazy(() => import('views/screens/userReports/consultantUserreport/index'))
);
const AssetTrackinguser = Loadable(
    lazy(() => import('views/modules/UserModules/assetTracking/index'))
);
const TalentAcquision = Loadable(
    lazy(() => import('views/modules/UserModules/TalentAcquision/index'))
);
const SupplierInvoice = Loadable(
    lazy(() => import('views/modules/SupplierModules/Invoice/index'))
);
const TalentAcquisionSupplier = Loadable(
    lazy(() => import('views/modules/SupplierModules/TalentAcquision/index'))
);
const DashboardSupplier = Loadable(
    lazy(() => import('views/screens/dashboard/SupplierDashboard/index.js'))
);
const TrainingDashboard = Loadable(
    lazy(() => import('views/modules/AdminModules/Training/index'))
);
const TrainingUserDashboard = Loadable(
    lazy(() => import('views/modules/UserModules/Training/index'))
);
const TrainingSession = Loadable(
    lazy(() => import('views/modules/AdminModules/Training/TrainingSession'))
);
const TrainingUserDocument = Loadable(
    lazy(() =>
        import('views/modules/UserModules/Training/TrainingUserDocument')
    )
);
const TraningReport = Loadable(
    lazy(() => import('views/screens/report/TrainingReport/index'))
);
// const TrainingDashboard = Loadable(lazy(() => import('views/modules/AdminModules/Training/index')))
const PerfomanceEvaluationInstrunction = Loadable(
    lazy(() =>
        import(
            'views/modules/UserModules/PerfomanceEvaluation/Instrunction/index'
        )
    )
);
const PerfomanceEvaluationForm = Loadable(
    lazy(() =>
        import('views/modules/UserModules/PerfomanceEvaluation/Exams/index')
    )
);
const PerfomanceEvaluationAdmin = Loadable(
    lazy(() =>
        import(
            'views/modules/AdminModules/PerfomanceEvaluation/evaluationResult/index'
        )
    )
);
const OverviewevaluEmp = Loadable(
    lazy(() =>
        import(
            'views/modules/AdminModules/PerfomanceEvaluation/overviewEvaluation/index'
        )
    )
);
const PerfomanceOverview = Loadable(
    lazy(() =>
        import('views/modules/UserModules/PerfomanceEvaluation/Overview/index')
    )
);
// const TrainingDashboard = Loadable(lazy(() => import('views/modules/AdminModules/Training/index')))
// const TrainingUserDashboard = Loadable(lazy(() => import('views/modules/UserModules/Training/index')))
// const TrainingSession = Loadable(lazy(() => import('views/modules/AdminModules/Training/TrainingSession')))
// const TrainingUserSession = Loadable(lazy(() => import('views/modules/UserModules/Training/TrainingSession')))
// const TraningReport = Loadable(lazy(() => import('views/screens/report/TrainingReport/index')));
// import MainLayout from 'layout/MainLayout';
const MainLayout = Loadable(lazy(() => import('layout/MainLayout/index')));

const routes = [
    {
        path: '/',
        crumbs: 'Dashboard',
        icon: <DesktopOutlined />,
        element: <MainLayout />,
        roles: ['ADMIN', 'USER', 'APPROVER', 'Supplier']
    },
    {
        path: '/dashboard',
        crumbs: 'Dashboard',
        icon: <DesktopOutlined />,
        element: <Dashboard />,
        roles: ['ADMIN', 'USER', 'APPROVER', 'Supplier']
    },
    {
        path: '/userlistcard',
        crumbs: 'Pending List',
        icon: <FileOutlined />,
        element: <UserListCard />,
        roles: ['ADMIN', 'APPROVER']
    },
    {
        path: '/timesheetadmin',
        crumbs: 'Timesheet',
        icon: <ClockCircleOutlined />,
        element: <TimesheetAdmin />,
        roles: ['ADMIN']
    },
    {
        path: '/timesheet',
        crumbs: 'Timesheet',
        icon: <ClockCircleOutlined />,
        element: <Timesheet />,
        roles: ['APPROVER', 'USER']
    },
    {
        path: '/assettrackinguser',
        crumbs: 'Asset Tracking',
        icon: <FundProjectionScreenOutlined />,
        element: <AssetTrackinguser />,
        roles: ['APPROVER', 'USER']
    },
    {
        path: '/assettrackingadmin',
        crumbs: 'Asset Tracking',
        icon: <FundProjectionScreenOutlined />,
        element: <AssetTrackingAdmin />,
        roles: ['ADMIN']
    },
    {
        path: '/tasksadmin',
        crumbs: 'Tasks',
        icon: <FundProjectionScreenOutlined />,
        element: <TasksAdmin />,
        roles: ['ADMIN']
    },
    {
        path: '/tasksuser',
        crumbs: 'Tasks',
        icon: <FundProjectionScreenOutlined />,
        element: <TasksUser />,
        roles: ['USER']
    },
    {
        path: '/talentacquisiondashboard',
        crumbs: 'Talent Acquisition',
        icon: <DesktopOutlined />,
        element: <Talentacquisoin />,
        roles: ['ADMIN']
    },
    {
        path: '/trainingdashboard',
        crumbs: 'Training Session',
        icon: <IconTrekking stroke={2} />,
        element: <TrainingDashboard />,
        roles: ['ADMIN']
    },
    {
        path: '/trainingSession',
        crumbs: 'Training Session',
        icon: <IconTrekking stroke={2} />,
        element: <TrainingSession />,
        roles: ['ADMIN']
    },
    {
        path: '/traininguserdashboard',
        crumbs: 'Training Session',
        icon: <IconTrekking stroke={2} />,
        element: <TrainingUserDashboard />,
        roles: ['USER', 'ADMIN', 'APPROVER']
    },
    {
        path: '/TrainingUserDocument',
        crumbs: 'Training User Document',
        icon: <IconTrekking stroke={2} />,
        element: <TrainingUserDocument />,
        roles: ['ADMIN']
    },
    // { path: '/trainingdashboard', crumbs: 'Training Session', icon: <IconTrekking stroke={2} />, element: <TrainingDashboard />, roles: ['ADMIN'] },
    {
        path: '/supplierinvoiceadmin',
        crumbs: 'Invoice',
        icon: <FileExclamationTwoTone />,
        element: <SupplierInvoiceAdmin />,
        roles: ['ADMIN']
    },
    {
        path: '/supplierinvoice',
        crumbs: 'Invoice',
        icon: <FileExclamationTwoTone />,
        element: <SupplierInvoice />,
        roles: ['Supplier']
    },
    {
        path: '/SupplierUser',
        crumbs: 'Consultants',
        icon: <UserSwitchOutlined />,
        element: <SupplierUser />,
        roles: ['Supplier']
    },
    {
        path: '/client',
        crumbs: 'Client',
        icon: <BankTwoTone />,
        element: <Client />,
        roles: ['ADMIN']
    },
    {
        path: '/project',
        crumbs: 'Project',
        icon: <ProjectOutlined />,
        element: <Project />,
        roles: ['ADMIN']
    },
    {
        path: '/user',
        crumbs: 'User',
        icon: <UserOutlined />,
        element: <User />,
        roles: ['ADMIN']
    },
    {
        path: '/supplier',
        crumbs: 'Supplier',
        icon: <TeamOutlined />,
        element: <Supplier />,
        roles: ['ADMIN']
    },
    {
        path: '/role',
        crumbs: 'Role',
        icon: <SettingOutlined />,
        element: <RoleUser />,
        roles: ['ADMIN']
    },
    {
        path: '/settings',
        crumbs: 'Settings',
        icon: <SettingOutlined />,
        element: <Settings />,
        roles: ['ADMIN']
    },
    {
        path: '/consultantreports',
        crumbs: 'Consultants Report',
        icon: <PieChartOutlined />,
        element: <ConsultantUserReport />,
        roles: ['USER', 'APPROVER']
    },
    {
        path: '/contractoremp',
        crumbs: 'Consultant Report',
        icon: <SnippetsTwoTone />,
        element: <ContractorempReport />,
        roles: ['Supplier']
    },
    {
        path: '/trainingreport',
        crumbs: 'Training Report',
        icon: <IconTrekking />,
        element: <TraningReport />,
        roles: ['USER,ADMIN']
    },

    {
        path: '/fteemp',
        crumbs: 'FTE Employee',
        icon: <FileSearchOutlined />,
        element: <FteempReport />,
        roles: ['ADMIN']
    },
    {
        path: '/userreport',
        crumbs: 'User Report',
        icon: <IdcardTwoTone />,
        element: <UserReport />,
        roles: ['ADMIN']
    },
    {
        path: '/tsstatusreport',
        crumbs: 'Timesheet Status Report',
        icon: <PieChartTwoTone />,
        element: <TimesheetStatusReport />,
        roles: ['ADMIN']
    },
    {
        path: '/assettrackingreport',
        crumbs: 'Asset Tracking Report',
        icon: <FundTwoTone />,
        element: <AssetTrackingReport />,
        roles: ['ADMIN']
    },
    {
        path: '/assettrackingcount',
        crumbs: 'Asset tracking Count',
        icon: <FundProjectionScreenOutlined />,
        element: <AssetTrackingCountReport />,
        roles: ['ADMIN']
    },
    {
        path: '/w9coireport',
        crumbs: 'W9 COI Report',
        icon: <FileExclamationOutlined />,
        element: <W9CoiReport />,
        roles: ['ADMIN']
    },
    {
        path: '/invoicegraph',
        crumbs: 'Invoice Trend Report',
        icon: <AppstoreTwoTone />,
        element: <InvoiceReportGraph />,
        roles: ['ADMIN']
    },
    {
        path: '/supplierinvoicereport',
        crumbs: 'Supplier Invoice Report',
        icon: <FileOutlined />,
        element: <SupplierinvoiceReport />,
        roles: ['ADMIN']
    },
    {
        path: '/profilesetting',
        crumbs: 'Profile Setting',
        icon: <SettingOutlined />,
        element: <ProfileSetting />,
        roles: ['ADMIN', 'USER']
    },
    {
        path: '/profilesettingsupplier',
        crumbs: 'Profile Setting Supplier',
        icon: <SettingOutlined />,
        element: <ProfileSettingSupplier />,
        roles: ['Supplier']
    },
    {
        path: '/talentacquisiondashboard/manageprofile',
        crumbs: 'Manage Profile',
        icon: <SettingOutlined />,
        element: <ManageProfile />,
        roles: ['ADMIN']
    },
    {
        path: '/talentacquisiondashboard/manageposition',
        crumbs: 'Manage Position',
        icon: <SettingOutlined />,
        element: <ManagePosition />,
        roles: ['ADMIN']
    },
    {
        path: '/talentacquisiondashboard/manageprofile/talentprofileview',
        crumbs: 'Profile View',
        icon: <SettingOutlined />,
        element: <TalentProfileView />,
        roles: ['ADMIN', 'USER', 'APPROVER', 'Supplier']
    },
    {
        path: '/talentacquision',
        crumbs: 'Talent Acquisition Profiles',
        icon: <SettingOutlined />,
        element: <TalentAcquision />,
        roles: ['USER', 'APPROVER']
    },
    {
        path: '/talentacquisionsupplier',
        crumbs: 'Talent Acquisition Suppliers',
        icon: <SettingOutlined />,
        element: <TalentAcquisionSupplier />,
        roles: ['Supplier']
    },
    // { path: `/dashboard`, crumbs: 'Dashboard', icon: <SettingOutlined />, element: <DashboardSupplier />, roles: ['Supplier'] },
    {
        path: `/userlistcard/userweeksheet/:WeekKey/:userKey`,
        crumbs: 'Timesheets',
        icon: <SettingOutlined />,
        element: <UserWeeksheet />,
        roles: ['ADMIN', 'APPROVER']
    },
    {
        path: `/evaluationoverview/perfomanceevaluationinstrunction`,
        crumbs: 'Perfomance Evaluation Instrunction',
        icon: <StarOutlined />,
        element: <PerfomanceEvaluationInstrunction />,
        roles: ['ADMIN', 'USER', 'APPROVER']
    },
    {
        path: `/evaluationoverview/perfomanceevaluationinstrunction/perfomanceform`,
        crumbs: 'Self Assement',
        icon: <HighlightOutlined />,
        element: <PerfomanceEvaluationForm />,
        roles: ['USER', 'APPROVER']
    },
    {
        path: `/overviewevaluationemp/perfomanceevaluation`,
        crumbs: 'Manager Assessment & Peer Feedback',
        icon: <HighlightOutlined />,
        element: <PerfomanceEvaluationAdmin />,
        roles: ['ADMIN']
    },
    {
        path: `/overviewevaluationemp`,
        crumbs: 'Performance Evaluation',
        icon: <HighlightOutlined />,
        element: <OverviewevaluEmp />,
        roles: ['ADMIN']
    },
    {
        path: `/evaluationoverview`,
        crumbs: 'Performance Evaluation',
        icon: <HighlightOutlined />,
        element: <PerfomanceOverview />,
        roles: ['USER']
    }
];

// const routes = [
//   { path: '/dashboard', crumbs: 'Dashboard',icon:<HomeOutlined/>, crumbs: 'Dashboard', element: <Dashboard />, roles: ['ADMIN', 'USER', 'APPROVER', 'Supplier'] },
//   { path: '/userlistcard', crumbs: 'Pending List', icon:<HomeOutlined/>,element: <UserListCard />, roles: ['ADMIN', 'APPROVER'] },
//   { path: '/timesheetadmin', crumbs: 'Timesheet', element: <TimesheetAdmin />, roles: ['ADMIN'] },
//   { path: '/timesheet', crumbs: 'Timesheet', element: <Timesheet />, roles: ['APPROVER', 'USER'] },
//   { path: '/assettrackinguser', crumbs: 'Asset Tracking', element: <AssetTrackinguser />, roles: ['APPROVER', 'USER'] },
//   { path: '/assettrackingadmin', crumbs: 'Asset Tracking', element: <AssetTrackingAdmin />, roles: ['ADMIN'] },
//   { path: '/talentacquisiondashboard', icon:<DesktopOutlined/>, crumbs: 'Talent Acquision', element: <Talentacquisoin />, roles: ['ADMIN'] },
//   { path: '/supplierinvoiceadmin', crumbs: 'Invoice', element: <SupplierInvoiceAdmin />, roles: ['ADMIN'] },
//   { path: '/supplierinvoice', crumbs: 'Invoice', element: <SupplierInvoice />, roles: ['Supplier'] },
//   { path: '/SupplierUser', crumbs: 'Consultants', element: <SupplierUser />, roles: ['Supplier'] },
//   { path: '/client', crumbs: 'Client', element: <Client />, roles: ['ADMIN'] },
//   { path: '/project', crumbs: 'Project', element: <Project />, roles: ['ADMIN'] },
//   { path: '/user', crumbs: 'User', element: <User />, roles: ['ADMIN'] },
//   { path: '/supplier', crumbs: 'Supplier', element: <Supplier />, roles: ['ADMIN'] },
//   { path: '/settings', crumbs: 'Settings', element: <Settings />, roles: ['ADMIN'] },
//   { path: '/consultantreports', crumbs: 'Consultants Report', element: <ConsultantUserReport />, roles: ['USER', 'APPROVER'] },
//   { path: '/contractoremp', crumbs: 'Consultant Report', element: <ContractorempReport />, roles: ['Supplier'] },
//   { path: '/fteemp', crumbs: 'FTE Employee', element: <FteempReport />, roles: ['ADMIN'] },
//   { path: '/userreport', crumbs: 'User Report', element: <UserReport />, roles: ['ADMIN'] },
//   { path: '/tsstatusreport', crumbs: 'Timesheet Status Report', element: <TimesheetStatusReport />, roles: ['ADMIN'] },
//   { path: '/assettrackingreport', crumbs: 'Asset Tracking Report', element: <AssetTrackingReport />, roles: ['ADMIN'] },
//   { path: '/assettrackingcount', crumbs: 'Asset tracking Count', element: <AssetTrackingCountReport />, roles: ['ADMIN'] },
//   { path: '/w9coireport', crumbs: 'W9 COI Report', element: <W9CoiReport />, roles: ['ADMIN'] },
//   { path: '/invoicegraph', crumbs: 'Invoice Trend Report', element: <InvoiceReportGraph />, roles: ['ADMIN'] },
//   { path: '/supplierinvoicereport', crumbs: 'Supplier Invoice Report', element: <SupplierinvoiceReport />, roles: ['ADMIN'] },
//   { path: '/profilesetting', crumbs: 'Profile Setting', element: <ProfileSetting />, roles: ['ADMIN', 'USER'] },
//   { path: '/profilesettingsupplier', crumbs: 'Profile Setting Supplier', element: <ProfileSettingSupplier />, roles: ['Supplier'] },
//   { path: '/talentacquisiondashboard/manageprofile',icon:<DesktopOutlined/>,  crumbs: 'Manage Profile', element: <ManageProfile />, roles: ['ADMIN'] },
//   { path: '/talentacquisiondashboard/manageposition',icon:<DesktopOutlined/>,  crumbs: 'Manage Position', element: <ManagePosition />, roles: ['ADMIN'] },
// ];

export default routes;
