// import React from 'react';
// import { Breadcrumb, theme } from 'antd';
// import { HomeOutlined } from '@ant-design/icons';
// import { Link, useLocation } from 'react-router-dom';
// import menuItems from './MenuItems';
// import routes from 'routes/Route.config';
// const pathToLabelMap = {};
// const generatePathToLabelMap = (items) => {
//   items.forEach(item => {
//     pathToLabelMap[item.path] = item.label;
//     if (item.children) {
//       generatePathToLabelMap(item.children);
//     }
//   });
// };

// generatePathToLabelMap(menuItems);

// const BreadcrumbComponent = () => {
//   const location = useLocation();
//   const pathSnippets = location.pathname.split('/').filter(i => i);

//   const breadcrumbItems = [
//     <Breadcrumb.Item key="home">
//       <Link to="/dashboard">
//         <HomeOutlined style={{ marginRight: 8 }} />
//         Home
//       </Link>
//     </Breadcrumb.Item>,
//     ...pathSnippets.map((snippet, index) => {
//       const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
//       const label = pathToLabelMap[`/${pathSnippets.slice(0, index + 1).join('/')}`];
//       return (
//         <Breadcrumb.Item key={url}>
//           <Link to={url}>{label || snippet}</Link>
//         </Breadcrumb.Item>
//       );
//     })
//   ];

//   const {
//     token: { colorText, fontSizeLG }
//   } = theme.useToken();

//   return (
//     <Breadcrumb
//       style={{
//         padding: '16px 0',
//         // background: '#f0f2f5',
//         borderRadius: '8px',
//         marginBottom: '16px',
//         fontSize: fontSizeLG,
//         color: colorText,
//         fontWeight: 'bold'
//       }}
//       separator=">"
//     >
//       {breadcrumbItems}
//     </Breadcrumb>
//   );
// };

// export default BreadcrumbComponent;


// import React from 'react';
// import { Breadcrumb, theme } from 'antd';
// import { HomeOutlined } from '@ant-design/icons';
// import { Link, useLocation } from 'react-router-dom';
// import routes from 'routes/Route.config'; 

// const pathToLabelMap = {};

// const generatePathToLabelMap = (items) => {
//   items.forEach(item => {
//     pathToLabelMap[item.path] = item.crumbs;
//     if (item.children) {
//       generatePathToLabelMap(item.children);
//     }
//   });
// };

// generatePathToLabelMap(routes); 

// const BreadcrumbComponent = () => {
//   const location = useLocation();
//   const pathSnippets = location.pathname.split('/').filter(i => i);

//   const breadcrumbItems = [
//     <Breadcrumb.Item key="home">
//       <Link to="/dashboard">
//         <HomeOutlined style={{ marginRight: 8 }} />
//         Home
//       </Link>
//     </Breadcrumb.Item>,
//     ...pathSnippets.map((snippet, index) => {
//       const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
//       const label = pathToLabelMap[url]; 
//       return (
//         <Breadcrumb.Item key={url}>
//           <Link to={url}>{label || snippet}</Link>
//         </Breadcrumb.Item>
//       );
//     })
//   ];

//   const {
//     token: { colorText, fontSizeLG }
//   } = theme.useToken();

//   return (
//     <Breadcrumb
//       style={{
//         padding: '16px 0',
//         borderRadius: '8px',
//         marginBottom: '16px',
//         fontSize: fontSizeLG,
//         color: colorText,
//         fontWeight: 'bold'
//       }}
//       separator=">"
//     >
//       {breadcrumbItems}
//     </Breadcrumb>
//   );
// };

// export default BreadcrumbComponent;


// ===============================================with icon


// import React from 'react';
// import { Breadcrumb, theme } from 'antd';
// import { HomeOutlined, RightOutlined } from '@ant-design/icons';
// import { Link, useLocation } from 'react-router-dom';
// import routes from 'routes/Route.config';

// const pathToRouteMap = {};

// const generatePathToRouteMap = (items) => {
//   items.forEach(item => {
//     pathToRouteMap[item.path] = item;
//     if (item.children) {
//       generatePathToRouteMap(item.children);
//     }
//   });
// };

// generatePathToRouteMap(routes); 

// const BreadcrumbComponent = () => {
//   const location = useLocation();
//   const pathSnippets = location.pathname.split('/').filter(i => i);

//   const breadcrumbItems = pathSnippets.map((snippet, index) => {
//     const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
//     const route = pathToRouteMap[url]; 
//     const icon = route && route.icon;
//     const label = route && route.crumbs; 


//     return (
//       <Breadcrumb.Item key={url} >
//         <Link to={url} style={{color:'#1b3665'}}>
//           {icon && <icon.type style={{ marginRight: 8 }} />}
//           {label ? label : snippet}
//         </Link>
//       </Breadcrumb.Item>
//     );
//   });

//   const {
//     token: { colorText, fontSizeLG }
//   } = theme.useToken();

//   return (
//     <Breadcrumb
//       style={{
//         padding: '16px 0',
//         borderRadius: '8px',
//         marginBottom: '16px',
//         fontSize: fontSizeLG,
//         color: '#1b3665',
//         fontWeight: 'bold'
//       }}
//       separator={<RightOutlined style={{ fontSize: '12px' ,color:'#1b3665'}} />}
//     >
//       <Breadcrumb.Item key="home">
//         <Link to="/dashboard" style={{color:'#1b3665'}}>
//           <HomeOutlined style={{ marginRight: 8 }} />
//           Home
//         </Link>
//       </Breadcrumb.Item>
//       {breadcrumbItems}
//     </Breadcrumb>
//   );
// };

// export default BreadcrumbComponent;



// import React from 'react';
// import { Breadcrumb, theme } from 'antd';
// import { HomeOutlined, RightOutlined } from '@ant-design/icons';
// import { Link, useLocation } from 'react-router-dom';
// import routes from 'routes/Route.config';

// const pathToRouteMap = {};

// const generatePathToRouteMap = (items) => {
//   items.forEach(item => {
//     pathToRouteMap[item.path] = item;
//     if (item.children) {
//       generatePathToRouteMap(item.children);
//     }
//   });
// };

// generatePathToRouteMap(routes);

// const BreadcrumbComponent = () => {
//   const location = useLocation();
//   const pathSnippets = location.pathname.split('/').filter(i => i);

//   // Function to check if a snippet is a parameter
//   const isParameter = (snippet) => /^[0-9]+$/.test(snippet);

//   const breadcrumbItems = pathSnippets.map((snippet, index) => {
//     if (isParameter(snippet)) return null; // Skip parameters

//     const url = `/${pathSnippets.slice(0, index + 1).filter(s => !isParameter(s)).join('/')}`;
//     const route = pathToRouteMap[url];
//     const icon = route && route.icon;
//     const label = route && route.crumbs;

//     return (
//       <Breadcrumb.Item key={url}>
//         <Link to={url} style={{ color: '#1b3665' }}>
//           {icon && <icon.type style={{ marginRight: 8 }} />}
//           {label ? label : snippet}
//         </Link>
//       </Breadcrumb.Item>
//     );
//   }).filter(item => item !== null); // Filter out null items

//   const {
//     token: { colorText, fontSizeLG }
//   } = theme.useToken();

//   return (
//     <Breadcrumb
//       style={{
//         padding: '16px 0',
//         borderRadius: '8px',
//         marginBottom: '16px',
//         fontSize: fontSizeLG,
//         color: '#1b3665',
//         fontWeight: 'bold'
//       }}
//       separator={<RightOutlined style={{ fontSize: '12px', color: '#1b3665' }} />}
//     >
//       <Breadcrumb.Item key="home">
//         <Link to="/dashboard" style={{ color: '#1b3665' }}>
//           <HomeOutlined style={{ marginRight: 8 }} />
//           Home
//         </Link>
//       </Breadcrumb.Item>
//       {breadcrumbItems}
//     </Breadcrumb>
//   );
// };

// export default BreadcrumbComponent;


import React from 'react';
import { Breadcrumb, theme } from 'antd';
import { HomeOutlined, RightOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import routes from 'routes/Route.config';



const BreadcrumbComponent = () => {
  const userROle = JSON.parse(localStorage.getItem('userRole'));
  const pathToRouteMap = {};

  const generatePathToRouteMap = (items) => {
    items.forEach(item => {
      const path = item.path.replace(/\/:\w+/g, '');
      pathToRouteMap[path] = item;
      if (item.children) {
        generatePathToRouteMap(item.children);
      }
    });
  };

  generatePathToRouteMap(routes);
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  const isParameter = (snippet) => /^[0-9]+$/.test(snippet);

  const breadcrumbItems = pathSnippets.map((snippet, index) => {
    const currentPath = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const route = pathToRouteMap[currentPath.replace(/\/\d+/g, '')];
    const icon = route && route.icon;
    const label = route ? route.crumbs : snippet;

    return !isParameter(snippet) ? (
      <Breadcrumb.Item key={currentPath}>
        <Link to={currentPath} style={{ color: '#1b3665' }}>
          {icon && <icon.type style={{ marginRight: 8 }} />}
          {label}
        </Link>
      </Breadcrumb.Item>
    ) : null;
  }).filter(item => item !== null);

  const {
    token: { colorText, fontSizeLG }
  } = theme.useToken();

  return (
    <Breadcrumb
      style={{
        padding: '16px 0',
        borderRadius: '8px',
        marginBottom: '16px',
        fontSize: fontSizeLG,
        color: '#1b3665',
        fontWeight: 'bold'
      }}
      separator={<RightOutlined style={{ fontSize: '12px', color: '#1b3665' }} />}
    >
       <Breadcrumb.Item key="home">
          <Link to="/dashboard" style={{ color: '#1b3665' }}>
            <HomeOutlined style={{ marginRight: 8 }} />
            Home
          </Link>
        </Breadcrumb.Item> 
      {/* {userROle?.id != 10 ?
        <Breadcrumb.Item key="home">
          <Link to="/dashboard" style={{ color: '#1b3665' }}>
            <HomeOutlined style={{ marginRight: 8 }} />
            Home
          </Link>
        </Breadcrumb.Item> :
        <Breadcrumb.Item key="home">
          <Link to="/dashboard" style={{ color: '#1b3665' }}>
            <HomeOutlined style={{ marginRight: 8 }} />
            Home
          </Link>
        </Breadcrumb.Item>} */}
      {breadcrumbItems}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
