// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import './index.scss';

// import NotFoundimg from 'assets/page_not_found/Pagenotfound.jpg';
// import { Typography } from 'antd';
// const NotFoundPage = () => {
//     const navigate = useNavigate()
//     const gotoHome = () => {
//         navigate('/dashboard')
//         window.location.reload()
//     }
//     return (
//         <div className="not-found-container">
//             <img
//                 src={NotFoundimg}
//                 alt="404 Not Found"
//                 className="not-found-image"
//             />
//             <h1 className="not-found-title">Oops! Page Not Found</h1>
//             <p className="not-found-text">
//                 The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
//             </p>
//             <Typography onClick={gotoHome} className="not-found-link cursor_pointer">Go to Homepage</Typography>
//         </div>
//     );
// };

// export default NotFoundPage;

// src/views/NotFoundPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import NotFoundimg from 'assets/page_not_found/Pagenotfound.jpg';
import { Typography, Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const NotFoundPage = () => {
    const navigate = useNavigate();

    const gotoHome = () => {
        navigate('/dashboard');
        window.location.reload()
    };

    return (
        <div className="not-found-container">
            <img
                src={NotFoundimg}
                alt="404 Not Found"
                className="not-found-image"
            />
            <Title className="not-found-title">Temporary Access Problem</Title>
            <Paragraph className="not-found-text">
                The Requested Page could not be served temporarily. Try navigating from home page.
            </Paragraph>
            <Button type="primary" icon={<HomeOutlined />} onClick={gotoHome} className="not-found-button">
                Go to Homepage
            </Button>
        </div>
    );
};

export default NotFoundPage;
