// assets
import { IconBrandChrome, IconServer, IconBoxMargin, IconHelp, IconDashboard, IconAperture, IconCode, IconSettingsAutomation, IconDeviceDesktop, IconFileText, IconUser, IconHome } from '@tabler/icons';
// constant
const icons = { IconBrandChrome, IconServer, IconBoxMargin, IconHelp, IconDashboard, IconAperture, IconCode, IconSettingsAutomation, IconFileText, IconDeviceDesktop, IconUser, IconHome };

let userRole = localStorage.getItem('userRole')

console.log(userRole)
let userRoleLoc ; 
try {
    userRoleLoc = JSON.parse(userRole);
    console.log("User role:", userRoleLoc);
} catch (error) {
    console.error("Error parsing user role:", error);
    // Handle the error, such as setting userRoleLoc to a default value
    userRoleLoc = null;
}

if (userRoleLoc && userRoleLoc.id === 50) {  
    console.log("User role with id 50:", userRoleLoc);
}


// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

let userOther;

if(userRoleLoc && userRoleLoc.id ==50){
     userOther = {
        id: 'user-roadmap',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
                icon: icons.IconHome,
                breadcrumbs: false
            },
             
            {
                id: 'userlistcard',
                title: 'Pending List',
                type: 'item',
                url: '/userlistcard',
                icon: icons.IconReportSearch,
                breadcrumbs: false
            },
            {
                id: 'timesheet',
                title: 'Timesheet',
                type: 'item',
                url: '/timesheet',
                icon: icons.IconUser,
                breadcrumbs: false
            },
    
            {
                id: 'assettrackinguser',
                title: 'Asset Tracking',
                type: 'item',
                url: '/assettrackinguser',
                icon: icons.IconUser,
                breadcrumbs: false
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'collapse',
                url: '',
                icon: icons.IconAperture,
                external: false,
                children: [
                    {
                        id: 'consultantreports',
                        title: 'Approved Hours',
                        type: 'item',
                        url: '/consultantreports',
                        icon: icons.IconUser,
                        breadcrumbs: false
                    },
                    
                ]
            },
            
            // {
            //     id: 'report',
            //     title: 'Report',
            //     type: 'item',
            //     url: '/report',
            //     icon: icons.IconFileText,
    
            // },
        ]
    };
}else{

 
 userOther = {
    id: 'user-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'timesheet',
            title: 'Timesheet',
            type: 'item',
            url: '/timesheet',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'assetTracking',
            title: 'Asset Tracking',
            type: 'item',
            url: '/assettrackinguser',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'talentacquision',
            title: 'Talent Acquisition',
            type: 'item',
            url: '/talentacquision',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            url: '',
            icon: icons.IconAperture,
            external: false,
            children: [
                {
                    id: 'Consultantreport',
                    title: 'Approved Hours',
                    type: 'item',
                    url: '/consultantreports',
                    icon: icons.IconUser,
                    breadcrumbs: false
                },
                
            ]
        },
        
        // {
        //     id: 'report',
        //     title: 'Report',
        //     type: 'item',
        //     url: '/report',
        //     icon: icons.IconFileText,

        // },
    ]
};
}

export default userOther;
