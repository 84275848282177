/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Components routing
const Dashboard = Loadable(
    lazy(() => import('views/screens/dashboard/MainDashboard/index'))
);
const Client = Loadable(lazy(() => import('views/screens/admin/client/index')));
const Project = Loadable(
    lazy(() => import('views/screens/admin/project/index'))
);
const ContractorempReport = Loadable(
    lazy(() => import('views/screens/report/ContractorEmpReport/index'))
);
const TrainingReport = Loadable(
    lazy(() => import('views/screens/report/TrainingReport/index'))
);
const FteempReport = Loadable(
    lazy(() => import('views/screens/report/FteEmpReport/index'))
);
const UserReport = Loadable(
    lazy(() => import('views/screens/report/UserReport/index'))
);
const User = Loadable(lazy(() => import('views/screens/admin/user/index')));
const Supplier = Loadable(
    lazy(() => import('views/screens/admin/supplier/index'))
);
const RoleUser = Loadable(
    lazy(() => import('views/screens/admin/role/index'))
);
const TimesheetStatusReport = Loadable(
    lazy(() => import('views/screens/report/TimesheetStatusReport/index'))
);
const Timesheet = Loadable(
    lazy(() => import('views/screens/time_sheet_dashboard/index'))
);
const TimesheetAdmin = Loadable(
    lazy(() => import('views/screens/admin/timsheet/index'))
);
const TrainingDashboard = Loadable(
    lazy(() => import('views/modules/AdminModules/Training/index'))
);
const TrainingUserDashboard = Loadable(
    lazy(() => import('views/modules/UserModules/Training/index'))
);
const TrainingSession = Loadable(
    lazy(() => import('views/modules/AdminModules/Training/TrainingSession'))
);
const TrainingUserDocument = Loadable(
    lazy(() =>
        import('views/modules/UserModules/Training/TrainingUserDocument')
    )
);
const UserListCard = Loadable(
    lazy(() =>
        import(
            'views/screens/dashboard/adminDashboard/submittedlistDashboard/index'
        )
    )
);
const UserWeeksheet = Loadable(
    lazy(() =>
        import('views/screens/dashboard/adminDashboard/userTimesheet/index')
    )
);

const Settings = Loadable(
    lazy(() => import('views/screens/admin/settings/index'))
);

const AssetTrackingAdmin = Loadable(
    lazy(() => import('views/modules/AdminModules/assetTracking/index'))
);
const TasksAdmin = Loadable(
    lazy(() => import('views/modules/AdminModules/Tasks/index'))
);
const TasksUser = Loadable(
    lazy(() => import('views/modules/UserModules/Tasks/index'))
);
const AssetTrackingReport = Loadable(
    lazy(() => import('views/screens/report/AssetTrackingReport/index'))
);
const AssetTrackingCountReport = Loadable(
    lazy(() => import('views/screens/report/AssetTrackingCount/index'))
);
const InvoiceReportGraph = Loadable(
    lazy(() => import('views/screens/report/InvoiceReport/Graphindex'))
);

// utilities routing
const UtilsTypography = Loadable(
    lazy(() => import('views/utilities/Typography'))
);
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(
    lazy(() => import('views/utilities/MaterialIcons'))
);
const UtilsTablerIcons = Loadable(
    lazy(() => import('views/utilities/TablerIcons'))
);

const ProfileSetting = Loadable(
    lazy(() => import('views/screens/profile/profilesetting'))
);

const Talentacquisoin = Loadable(
    lazy(() => import('views/modules/AdminModules/TalentAcquision/index'))
);

const ManageProfile = Loadable(
    lazy(() =>
        import('views/modules/AdminModules/TalentAcquision/ManageProfile')
    )
);
const ManagePosition = Loadable(
    lazy(() =>
        import('views/modules/AdminModules/TalentAcquision/ManagePosition')
    )
);

const TalentProfileView = Loadable(
    lazy(() =>
        import('views/modules/AdminModules/TalentAcquision/TalentprofileView')
    )
);
const ProfileSettingSupplier = Loadable(
    lazy(() => import('views/screens/profile/profilesettingSupplier'))
);
const SupplierInvoice = Loadable(
    lazy(() => import('views/modules/SupplierModules/Invoice/index'))
);

// sample page routing
const SupplierInvoiceAdmin = Loadable(
    lazy(() => import('views/modules/AdminModules/Invoice/index'))
);
const SupplierUser = Loadable(
    lazy(() => import('views/modules/SupplierModules/suppplierUser/index'))
);

const W9CoiReport = Loadable(
    lazy(() => import('views/screens/report/w9CoiReport/index'))
);
const SupplierinvoiceReport = Loadable(
    lazy(() => import('views/screens/report/SupplierInvoiceReport/index'))
);
const TalentAcquisionSupplier = Loadable(
    lazy(() => import('views/modules/SupplierModules/TalentAcquision/index'))
);
const PerfomanceEvaluation = Loadable(
    lazy(() =>
        import(
            'views/modules/AdminModules/PerfomanceEvaluation/evaluationResult/index'
        )
    )
);
const OverviewevaluEmp = Loadable(
    lazy(() =>
        import(
            'views/modules/AdminModules/PerfomanceEvaluation/overviewEvaluation/index'
        )
    )
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <Dashboard />
                }
            ]
        },
        {
            path: '/overviewevaluationemp',
            element: <OverviewevaluEmp />
        },
        {
            path: 'overviewevaluationemp',
            children: [
                {
                    path: 'default',
                    element: <OverviewevaluEmp />
                }
            ]
        },
        {
            path: '/client',
            element: <Client />
        },
        {
            path: 'client',
            children: [
                {
                    path: 'default',
                    element: <Client />
                }
            ]
        },
        {
            path: '/overviewevaluationemp/perfomanceevaluation',
            element: <PerfomanceEvaluation />
        },
        {
            path: 'overviewevaluationemp/perfomanceevaluation',
            children: [
                {
                    path: 'default',
                    element: <PerfomanceEvaluation />
                }
            ]
        },
        // {
        //     path: '/perfomanceevaluationinstrunction/perfomanceform',
        //     element: <PerfomanceEvaluationInstrunction />
        // },
        // {
        //     path: 'perfomanceevaluationinstrunction/perfomanceform',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <PerfomanceEvaluationInstrunction />
        //         }
        //     ]
        // },
        {
            path: '/timesheet',
            element: <Timesheet />
        },
        {
            path: 'timesheet',
            children: [
                {
                    path: 'default',
                    element: <Timesheet />
                }
            ]
        },
        {
            path: '/timesheetadmin',
            element: <TimesheetAdmin />
        },
        {
            path: 'timesheetadmin',
            children: [
                {
                    path: 'default',
                    element: <TimesheetAdmin />
                }
            ]
        },
        // {
        //     path: '/trainingdashboard',
        //     element: <TrainingDashboard />
        // },
        // {
        //     path: 'trainingdashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <TrainingDashboard />
        //         }
        //     ]
        // },

        {
            path: '/trainingdashboard',
            element: <TrainingDashboard />
        },
        {
            path: 'trainingdashboard',
            children: [
                {
                    path: 'default',
                    element: <TrainingDashboard />
                }
            ]
        },

        {
            path: '/traininguserdashboard',
            element: <TrainingUserDashboard />
        },
        {
            path: 'traininguserdashboard',
            children: [
                {
                    path: 'default',
                    element: <TrainingUserDashboard />
                }
            ]
        },
        {
            path: '/TrainingUserDocument',
            element: <TrainingUserDocument />
        },
        {
            path: 'TrainingUserDocument',
            children: [
                {
                    path: 'default',
                    element: <TrainingUserDocument />
                }
            ]
        },

        {
            path: '/trainingSession',
            element: <TrainingSession />
        },
        {
            path: 'trainingSession',
            children: [
                {
                    path: 'default',
                    element: <TrainingSession />
                }
            ]
        },

        {
            path: '/userlistcard',
            element: <UserListCard />
        },
        {
            path: 'userlistcard',
            children: [
                {
                    path: 'default',
                    element: <UserListCard />
                }
            ]
        },
        {
            path: '/SupplierUser',
            element: <SupplierUser />
        },
        {
            path: 'SupplierUser',
            children: [
                {
                    path: 'default',
                    element: <SupplierUser />
                }
            ]
        },
        {
            path: `/userlistcard/userweeksheet/:WeekKey/:userKey`,
            element: <UserWeeksheet />
        },
        {
            path: 'userlistcard/userweeksheet',
            children: [
                {
                    path: 'default',
                    element: <UserWeeksheet />
                }
            ]
        },
        {
            path: '/project',
            element: <Project />
        },
        {
            path: 'project',
            children: [
                {
                    path: 'default',
                    element: <Project />
                }
            ]
        },
        {
            path: '/user',
            element: <User />
        },
        {
            path: 'user',
            children: [
                {
                    path: 'default',
                    element: <User />
                }
            ]
        },
        {
            path: '/supplier',
            element: <Supplier />
        },
        {
            path: 'supplier',
            children: [
                {
                    path: 'default',
                    element: <Supplier />
                }
            ]
        },
        {
            path: '/role',
            element: <RoleUser />
        },
        {
            path: 'role',
            children: [
                {
                    path: 'default',
                    element: <RoleUser />
                }
            ]
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: 'settings',
            children: [
                {
                    path: 'default',
                    element: <Settings />
                }
            ]
        },
        {
            path: '/assettrackingadmin',
            element: <AssetTrackingAdmin />
        },
        {
            path: 'assettrackingadmin',
            children: [
                {
                    path: 'default',
                    element: <AssetTrackingAdmin />
                }
            ]
        },
        {
            path: '/tasksadmin',
            element: <TasksAdmin />
        },
        {
            path: 'tasksadmin',
            children: [
                {
                    path: 'default',
                    element: <TasksAdmin />
                }
            ]
        },
        {
            path: '/tasksuser',
            element: <TasksUser />
        },
        {
            path: 'tasksuser',
            children: [
                {
                    path: 'default',
                    element: <TasksUser />
                }
            ]
        },
        {
            path: '/assettrackingreport',
            element: <AssetTrackingReport />
        },
        {
            path: 'assettrackingreport',
            children: [
                {
                    path: 'default',
                    element: <AssetTrackingReport />
                }
            ]
        },
        {
            path: '/assetTrackingCount',
            element: <AssetTrackingCountReport />
        },
        {
            path: 'assetTrackingCount',
            children: [
                {
                    path: 'default',
                    element: <AssetTrackingCountReport />
                }
            ]
        },

        // {
        //     path: '/report',
        //     element: <Report />
        // },
        // {
        //     path: 'report',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <Report />
        //         }
        //     ]
        // },
        {
            path: '/profilesetting',
            element: <ProfileSetting />
        },
        {
            path: 'profilesetting',
            children: [
                {
                    path: 'default',
                    element: <ProfileSetting />
                }
            ]
        },
        {
            path: '/contractoremp',
            element: <ContractorempReport />
        },
        {
            path: 'contractoremp',
            children: [
                {
                    path: 'default',
                    element: <ContractorempReport />
                }
            ]
        },
        {
            path: '/trainingreport',
            element: <TrainingReport />
        },
        {
            path: 'trainingreport',
            children: [
                {
                    path: 'default',
                    element: <TrainingReport />
                }
            ]
        },

        {
            path: '/invoicegraph',
            element: <InvoiceReportGraph />
        },
        {
            path: 'invoicegraph',
            children: [
                {
                    path: 'default',
                    element: <InvoiceReportGraph />
                }
            ]
        },
        {
            path: '/tsstatusreport',
            element: <TimesheetStatusReport />
        },
        {
            path: 'tsstatusreport',
            children: [
                {
                    path: 'default',
                    element: <TimesheetStatusReport />
                }
            ]
        },
        {
            path: '/fteemp',
            element: <FteempReport />
        },
        {
            path: 'fteemp',
            children: [
                {
                    path: 'default',
                    element: <FteempReport />
                }
            ]
        },

        {
            path: '/userreport',
            element: <UserReport />
        },
        {
            path: 'userreport',
            children: [
                {
                    path: 'default',
                    element: <UserReport />
                }
            ]
        },

        {
            path: '/talentacquisiondashboard',
            element: <Talentacquisoin />
        },
        {
            path: 'talentacquisiondashboard',
            children: [
                {
                    path: 'default',
                    element: <Talentacquisoin />
                }
            ]
        },
        {
            path: '/talentacquisiondashboard/manageprofile',
            element: <ManageProfile />
        },
        {
            path: 'talentacquisiondashboard/manageprofile',
            children: [
                {
                    path: 'default',
                    element: <ManageProfile />
                }
            ]
        },
        {
            path: '/talentacquisiondashboard/manageposition',
            element: <ManagePosition />
        },
        {
            path: 'talentacquisiondashboard/manageposition',
            children: [
                {
                    path: 'default',
                    element: <ManagePosition />
                }
            ]
        },
        {
            path: '/talentacquisiondashboard/manageprofile/talentprofileview',
            element: <TalentProfileView />
        },
        {
            path: 'talentprofileview',
            children: [
                {
                    path: 'default',
                    element: <TalentProfileView />
                }
            ]
        },
        // ==========================================supplier routes start =============================
        {
            path: '/supplierinvoice',
            element: <SupplierInvoice />
        },
        {
            path: 'supplierinvoice',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoice />
                }
            ]
        },
        {
            path: '/profilesettingsupplier',
            element: <ProfileSettingSupplier />
        },
        {
            path: 'profilesettingsupplier',
            children: [
                {
                    path: 'default',
                    element: <ProfileSettingSupplier />
                }
            ]
        },
        // ===================================supplier routes end ===========================
        {
            path: '/supplierinvoiceadmin',
            element: <SupplierInvoiceAdmin />
        },
        {
            path: 'supplierinvoiceadmin',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoiceAdmin />
                }
            ]
        },

        {
            path: '/talentacquisionsupplier',
            element: <TalentAcquisionSupplier />
        },
        {
            path: 'talentacquisionsupplier',
            children: [
                {
                    path: 'default',
                    element: <TalentAcquisionSupplier />
                }
            ]
        },

        {
            path: '/w9coireport',
            element: <W9CoiReport />
        },
        {
            path: 'w9coireport',
            children: [
                {
                    path: 'default',
                    element: <W9CoiReport />
                }
            ]
        },

        {
            path: '/supplierinvoicereport',
            element: <SupplierinvoiceReport />
        },
        {
            path: 'supplierinvoicereport',
            children: [
                {
                    path: 'default',
                    element: <SupplierinvoiceReport />
                }
            ]
        },

        // utils routing
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        }
    ]
};

export default MainRoutes;
