import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgot = Loadable(lazy(() => import('views/pages/authentication/authentication3/forgotpassword')));
const Sessiontimeout = Loadable(lazy(() => import('views/pages/sessionTimeout/index')));

const AuthEmailVerification = Loadable(lazy(() => import('views/pages/2fa_authentication/email_verification/index')));
const AuthOtpVerification = Loadable(lazy(() => import('views/pages/2fa_authentication/otp_verification/index')));
const Authsetpassword = Loadable(lazy(() => import('views/pages/2fa_authentication/setpassword/index')));
const AuthtrustDevice = Loadable(lazy(() => import('views/pages/2fa_authentication/trust_device/index')));
const ProfileSetting = Loadable(lazy(() => import('views/screens/profile/profilesetting')))
// const AuthtwoFalogin = 
const NotFound = Loadable(lazy(() => import('views/pages/Not-Found/index')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <NotFound />
        },
        {
            path: '/',
            element: <AuthLogin />,
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/singup',
            element: <AuthRegister />
        },
        {
            path: '/forgotpassword',
            element: <AuthForgot />
        },
        {
            path: '/verifyemail',
            element: <AuthEmailVerification />
        },
        {
            path: '/verifycode',
            element: <AuthOtpVerification />
        },
        {
            path: '/setpassword',
            element: <Authsetpassword />
        },
        {
            path: '/trustdevice',
            element: <AuthtrustDevice />
        },
        {
            path: '/sessionexpired',
            element: <Sessiontimeout />
        },
        // {
        //     path: '/profilesetting',
        //     element: <ProfileSetting />
        // }

    ]
};

export default AuthenticationRoutes;
