/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Components routing
const Dashboard = Loadable(lazy(() => import('views/screens/dashboard/MainDashboard/index')));
// utilities routing
const SupplierInvoice = Loadable(lazy(()=>import('views/modules/SupplierModules/Invoice/index')))
const SupplierUser = Loadable(lazy(()=>import('views/modules/SupplierModules/suppplierUser/index')))
const ProfileSettingSupplier = Loadable(lazy(() => import('views/screens/profile/profilesettingSupplier')))
const ContractorempReport = Loadable(lazy(() => import('views/screens/report/ContractorEmpReport/index')));
const TalentAcquisionSupplier = Loadable(lazy(() => import('views/modules/SupplierModules/TalentAcquision/index')))
const DashboardSupplier = Loadable(lazy(() => import('views/screens/dashboard/SupplierDashboard/index.js')))
const NotFound = Loadable(lazy(() => import('views/pages/Not-Found/index')))
const SUpplierRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '*',
            element: <NotFound />
        },
        {
            path: '*',
            children: [
                {
                    path: 'default',
                    element: <NotFound />
                }
            ]
        },
        {
            path: '/dashboard',
            element: <DashboardSupplier />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardSupplier />
                }
            ]
        },
        {
            path: '/profilesettingsupplier',
            element: <ProfileSettingSupplier />
        },
        {
            path: 'profilesettingsupplier',
            children: [
                {
                    path: 'default',
                    element: <ProfileSettingSupplier />
                }
            ]
        },
        {
            path: '/supplierinvoice',
            element: <SupplierInvoice />
        },
        {
            path: 'supplierinvoice',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoice />
                }
            ]
        },

        {
            path: '/SupplierUser',
            element: <SupplierUser />
        },
        {
            path: 'SupplierUser',
            children: [
                {
                    path: 'default',
                    element: <SupplierUser />
                }
            ]
        },
        {
            path: '/contractoremp',
            element: <ContractorempReport />
        },
        {
            path: 'contractoremp',
            children: [
                {
                    path: 'default',
                    element: <ContractorempReport />
                }
            ]
        },

        {
            path: '/talentacquisionsupplier',
            element: <TalentAcquisionSupplier />
        },
        {
            path: 'talentacquisionsupplier',
            children: [
                {
                    path: 'default',
                    element: <TalentAcquisionSupplier />
                }
            ]
        },
    ]
};

export default SUpplierRoutes;
