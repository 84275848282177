

import other from './other';
import userOther from './userOther';
import supplierMenu from './supplierMenu';
const menuItems = {
    items: []
};
let userRole = localStorage.getItem('userRole');
userRole = JSON.parse(userRole);
console.log(userRole)
try {
    if (userRole) {
        if (userRole.name === 'ADMIN') {
            menuItems.items = [other];
        } else if (userRole.name === "Supplier") {
            menuItems.items = [supplierMenu]
        }
        else {
            menuItems.items = [userOther];
        }
    } else {
        console.error("User role not found in localStorage.");
    }
} catch (e) {
    console.log(e)
}


console.log("Menu Items:", menuItems);

export default menuItems;
